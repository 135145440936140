// import AuthGuard from 'app/auth/AuthGuard';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import pagesRoutes from 'app/views/pages/PagesRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
const routes = [

  {
    element: (

        <MatxLayout />
    ),
    children: [...pagesRoutes],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="session/signin" /> },
];

export default routes;
