import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const AllmembersGSC = Loadable(lazy(() => import('./AllmembersGSC')));
const Importsports = Loadable(lazy(() => import('./Importsports')));
const Userreports = Loadable(lazy(() => import('./Userreports')));
const ClubAllAccess = Loadable(lazy(() => import('./ClubAllAccess')));
const Deletionrequests = Loadable(lazy(() => import('./Deletionrequests')));

const pagesRoutes = [
  { path: '/pages/allmembersGSC', element: <AllmembersGSC /> },
  { path: '/pages/importsports', element: <Importsports /> },
  { path: '/pages/userreports', element: <Userreports /> },
  { path: '/pages/cluballaccess', element: <ClubAllAccess /> },
  { path: '/pages/deletionrequests', element: <Deletionrequests /> },
];

export default pagesRoutes;
